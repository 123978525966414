<template>
  <div>
    <div class="active-invoice">
      <!--表格-->
      <el-table
          :data="eventList"
          border
          style="width: 99%;margin: 0 auto"
      >
        <!--活动标题-->
        <el-table-column
            prop="title"
            :label="$t('Title')"
        >
        </el-table-column>
        <!--活动开始时间-->
        <el-table-column
            prop="eventStartTime"
            width="165px"
            :label="$t('ActiveStart')"
        >
        </el-table-column>
        <!--缴费时间-->
        <el-table-column
            prop="paymentTime"
            width="180px"
            :label="$t('PayTime')"
        >
        </el-table-column>
        <!--申请人-->
        <el-table-column
            prop="realName"
            width="150"
            :label="$t('ApplyPeople')"
        >
        </el-table-column>
        <!--缴费金额-->
        <el-table-column
            prop="price"
            width="130"
            :label="$t('PayAmount')"
        >
        </el-table-column>
        <!--状态-->
        <el-table-column
            width="130"
            :label="$t('Status')"
        >
          <template slot-scope="scope">
            <span :style="{color:scope.row.needInvoice===1?'red':'black'}">
              {{ numToStatusActivity(scope.row.needInvoice) }}
            </span>
          </template>
        </el-table-column>
        <!--操作-->
        <el-table-column
            prop="operation"
            width="210"
            :label="$t('Operation')"
        >
          <template slot-scope="scope">
            <el-button
                type="text"
                size="small"
                @click="openDialog(scope.row)"
            >{{ $t("Detail") }}
            </el-button>
            <el-button
                type="text"
                size="small"
                :disabled="scope.row.needInvoice === 2"
                @click="setInvoiced(scope.row)"
            >{{ $t("Invoiced") }}
            </el-button>
<!--            <el-button
                type="text"
                size="small"
                style="color: red"
            >{{ $t("Delete") }}
            </el-button>-->
          </template>
        </el-table-column>
      </el-table>
      <!--分页-->
      <el-pagination
          background
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          layout="prev, pager, next"
          :page-size="pageSize"
          :total="total"
          style="margin: 10px auto 10px 20px"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import bus from '@/assets/js/bus'
import api from "@/fetch/api";

export default {
  name: 'ActivityInvoice',
  components: {},
  data() {
    const data = JSON.parse(localStorage.getItem("cacheChamber")).adminData;
    return {
      adminId: data.id,
      eventList:[],
      currentPage:1,
      pageSize:10,
      total:30,
    };
  },
  created() {
  },
  mounted() {
    this.initActivityInvoice(1)
    bus.$off("freshPage")
    bus.$on('freshPage',()=>{
      this.initActivityInvoice(this.currentPage)
    })
  },
  methods: {
    // 状态  0不需要 1需要 2已开
    numToStatusActivity(num){
      return num === 1 ? this.$t('NeedInvoice') : this.$t('Invoiced')
    },
    
  //   初始化
    initActivityInvoice(page){
      let param={
        currentAdmin: this.adminId,
        pageSize:10,
        page
      }
      api.getEventInvoice(param).then(res=>{
        this.eventList = res.data.pageList
        this.total = res.data.total
      })
    },
    
  //   分页
    handleCurrentChange(page){
      this.currentPage = page
      this.initActivityInvoice(page)
    },
    
  // 查看详情
    openDialog(data){
      data.dialog = true
      data.msg = false
      bus.$emit("openDialog",data)
    },
  
    //   已开发票
    setInvoiced(data){
      bus.$emit("setInvoiced",data)
    },
  }
};
</script>

<style lang="scss" scoped>

</style>